import { axios } from '../../axios'

export const getBlockedNumbers = (contest_id, params = {}) => {
    return axios.get(`/numbers/${contest_id}/blocked-numbers`, { params }).then(({ data }) => data)
}

export const blockNumber = (contest_id, number, params = {}) => {
    return axios.put(`/numbers/${contest_id}/block-number/${number}`, params).then(({ data }) => data)
}

export const releaseNumber = (contest_id, number, params = {}) => {
    return axios.put(`/numbers/${contest_id}/release-number/${number}`, params).then(({ data }) => data)
}