<template>
  <Layout :pagetitle="`Gerenciar sorteio - ${formRaffle.title}`">
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col text-center">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-outline-primary waves-effect waves-light"
                  @click="refreshAll"
                >
                  <i class="bx bx-sync label-icon"></i>
                  Atualizar dados
                </button>
                <router-link
                  class="btn btn-outline-info waves-effect waves-light"
                  :to="{
                    name: 'contest_orders',
                    params: {
                      id: $route.params.id
                  }}"
                >
                  <i class="mdi mdi-order-bool-ascending-variant"></i>
                  Ir para pedidos
                </router-link>
                <button
                  :disabled="formRaffle.status === 'FINISHED'"
                  type="button"
                  class="btn btn-outline-danger waves-effect waves-light"
                  @click="finishContest"
                >
                  <i class="bx bx-check-double label-icon"></i>
                  Finalizar sorteio
                </button>
                <button
                  v-if="formRaffle.status === 'ACTIVE'"
                  type="button"
                  class="btn btn-outline-warning waves-effect waves-light"
                  @click="standbyContest"
                >
                  <i class="bx bx bx-cart-alt label-icon"></i>
                  Pausar vendas
                </button>
                <button
                  v-if="formRaffle.status === 'STANDBY' || formRaffle.status === 'SOLD_OFF'"
                  type="button"
                  class="btn btn-outline-success waves-effect waves-light"
                  @click="activeContest"
                >
                  <i class="bx bx bx-cart-alt label-icon"></i>
                  Reativar vendas
                </button>
                <button
                  v-if="formRaffle.status != 'FINISHED' && formRaffle.status != 'SOLD_OFF'"
                  type="button"
                  class="btn btn-outline-warning waves-effect waves-light"
                  @click="soldOffContest"
                >
                  <b-icon icon="coin"></b-icon>
                  Marcar Sorteio como Esgotado
                </button>
                <button
                  v-if="counters.all.value === counters.free.value"
                  type="button"
                  class="btn btn-outline-secondary waves-effect waves-light"
                  @click="deleteContest"
                >
                  <i class="bx bx bx-cart-alt label-icon"></i>
                  Excluir sorteio
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3" v-for="(counter, key) in counters" :key="key">
          <div class="card border text-center" :class="`border-${counter.color}`">
            <div class="card-header bg-transparent" :class="`border-${counter.color}`">
              <h5 class="my-0" :class="`text-${counter.color}`">
                <i class="mdi mdi-bullseye-arrow me-3"></i>
                {{ counter.label }}
              </h5>
            </div>
            <div class="card-body">
              <h1 class="display-4" :class="`text-${counter.color}`">
                {{ counter.value ?? 0 }}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          formRaffle.status === 'FINISHED' &&
          formRaffle.winner_id &&
          formRaffle.winner_number
        "
        class="card border border-primary"
      >
        <div class="card-header bg-transparent border-primary text-center">
          <h5 class="my-0 text-primary">
            <i class="bx bx-check-double label-icon"></i>
            Sorteio encerrado
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <dl class="row mb-0">
                <dt class="col-sm-3"> Encerrado em: </dt>
                <dd class="col-sm-9"> {{ $d(formRaffle.finished_at, 'info') }} </dd>
                <dt class="col-sm-3"> Número sorteado: </dt>
                <dd class="col-sm-9"> {{ formRaffle.winner_number }} </dd>
                <dt class="col-sm-3"> Ganhador: </dt>
                <dd class="col-sm-9">
                  {{ formRaffle.winner.name }} |
                  <a
                    class="bgn btn-link"
                    target="_blank"
                    :href="whatsappLink(formRaffle.winner.name, formRaffle.winner.whatsapp)"
                  >
                    <i class="mdi mdi-whatsapp"></i>
                    {{ maskPhone(formRaffle.winner.whatsapp) }}
                  </a>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <strong class="card-title text-dark">ATENÇÃO! devido a descontinuídade deste painel e mudanças nas regras, o Progresso só deve ser usado no modo Esconder.</strong>
              <h4 class="card-title">
                Progresso
                <small class="text-dark">Controle a exibição do progresso do sorteio.</small>
              </h4>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div
                class="btn-group"
                role="group"
                style="width: 100%;"
              >
                <input
                  v-model="selectedMode"
                  value="hidden"
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  autocomplete="off"
                  @change="handleChangePercentage"
                />
                  <label class="btn btn-primary" for="btnradio1">
                    Esconder
                  </label>
                <input
                  v-model="selectedMode"
                  value="show_true"
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio2"
                  autocomplete="off"
                  @change="handleChangePercentage"
                />
                <label class="btn btn-primary" for="btnradio2">
                  Valor atual
                </label>
                <input
                  v-model="selectedMode"
                  value="show_custom"
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio3"
                  autocomplete="off"
                  @change="handleChangePercentage"
                />
                <label class="btn btn-primary" for="btnradio3">
                  Customizada
                </label>
              </div>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col">
              <div v-if="selectedMode === 'hidden'">
                <h5>
                  Esconder porcentagem <span class="text-muted fw-normal ms-2"></span>
                </h5>
                <p>Ao selecionar essa opção, a barra de progresso do sorteio torna-se
                  oculta.</p>
              </div>
              <div v-if="selectedMode === 'show_true'">
                <h5>
                  Porcentagem atual <span class="text-muted fw-normal ms-2"></span>
                </h5>
                <p>Ao selecionar essa opção, a barra de progresso do sorteio vai exibir a
                  porcentagem real de números que já foram comprados pelos clientes.</p>
              </div>
              <div v-if="selectedMode === 'show_custom'">
                <h5>
                  Porcentagem customizada <span class="text-muted fw-normal ms-2"></span>
                </h5>
                <p>Ao selecionar essa opção, você pode selecionar a porcentagem que que vai ser exibida para os
                  clientes no site. (Esse valor não vai afetar a porcentagem real do sorteio)</p>
                <div class="col-md-12">
                  <div class="p-3">
                    <div class="p-3">
                      <Slider
                          v-model="changePercentage.custom_percentage"
                          @update="handleChangePercentage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3" v-if="formRaffle.status === 'ACTIVE'">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h4 class="card-title">
                Controles do sorteio
                <small class="text-dark">
                  Personalize os comportamentos do sorteio durante as vendas
                </small>
              </h4>
            </div>
            <div class="col" style="text-align: right;">
              <button
                :disabled="updatingControls"
                class="btn btn-outline-success waves-effect waves-light"
                type="button"
                style="width: 15rem;"
                @click="updateControls"
              >
                <i
                  class="bx font-size-16 align-middle me-2"
                  :class="updatingControls ? 'bx-loader bx-spin' : 'bx-save'"
                ></i>
                {{ updatingControls ? 'Atualizando controles' : 'Atualizar controles' }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-3 row mt-3">
            <div class="col-md-6">
              <b-form-checkbox
                switch
                id="website-ranking"
                v-model="controls.website_ranking"
                name="website-ranking"
                :value="3"
                :unchecked-value="0"
                :disabled="isFreeAccount()"
              >
                Exibir ranking de compradores no site
              </b-form-checkbox>
            </div>
            <div class="col-md-6 pt-1">
              &laquo; Exibe os 3 maiores compradores na página
              do sorteio no site, ordenados pelo valor investido em ordem decrescente.
            </div>
          </div>

          <div class="mb-3 row mt-3">
            <div class="col-md-6">
              <b-form-checkbox
                switch
                id="show-on-home-page"
                v-model="controls.show_on_home_page"
                name="show-on-home-page"
              >
                Mostrar sorteio na página inicial
              </b-form-checkbox>
            </div>
            <div class="col-md-6 pt-1">
              <div v-if="controls.show_on_home_page">
                &laquo; Desativar esta opção, faz com que o sorteio não fique visível na página inicial, sendo possível acessá-lo apenas com o link direto:
              </div>
              <div v-else class="text-info">
                <button
                  v-if="controls.slug"
                  type="button"
                  class="btn btn-outline-info waves-effect waves-light text-center btn-sm"
                  @click.prevent="copyContestLink(controls)"
                  style="width: 100%;"
                >
                  <i class="mdi mdi-content-copy"></i>
                  Copiar link deste sorteio
                </button>
                <span v-else>
                  Você poderá copiar o link direto após salvar o sorteio
                </span>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="max_order_numbers" class="col-md-4 col-form-label">
              Quantidade mínima de números em um único pedido
            </label>
            <div class="col-md-3">
              <input
                v-model="controls.min_order_numbers"
                class="form-control text-center"
                type="number"
                id="min_order_numbers"
                :max="controls.max_order_numbers > 0 ? controls.max_order_numbers : controls.quantity"
              >
            </div>
            <div class="col-md-5 pt-2">
              &laquo; Deixe em branco ou com valor "0" caso não queira aplicar limites.
            </div>
          </div>
          <div class="mb-3 row">
            <label for="max_order_numbers" class="col-md-4 col-form-label">
              Quantidade máxima de números em um único pedido
            </label>
            <div class="col-md-3">
              <input
                v-model="controls.max_order_numbers"
                class="form-control text-center"
                type="number"
                id="max_order_numbers"
                min="0"
              >
            </div>
            <div class="col-md-5 pt-2">
              &laquo; Deixe em branco ou com valor "0" caso não queira aplicar limites.
            </div>
          </div>
          <div class="mb-3 row">
            <label for="max_pending_orders" class="col-md-4 col-form-label">
              Quantidade máxima de pedidos pendentes por cliente
            </label>
            <div class="col-md-3">
              <input
                v-model="controls.max_pending_orders"
                class="form-control text-center"
                type="number"
                id="max_pending_orders"
                min="0"
              >
            </div>
            <div class="col-md-5 pt-2">
              &laquo; Deixe em branco ou com valor "0" para não aplicar limites.
            </div>
          </div>
          <div class="mb-3 row">
            <label for="pending_lifetime" class="col-md-4 col-form-label">
              Tempo até que um pedido pendente seja cancelado (minutos)
            </label>
            <div class="col-md-3">
              <b-form-select
                  v-model="controls.pending_lifetime"
              >
                  <b-form-select-option
                      v-for="option in cancelPendingTimeOptions"
                      :key="option.value"
                      :value="option.value"
                  >
                      {{ option.text }}
                  </b-form-select-option>
              </b-form-select>
            </div>
            <div class="col-md-5 pt-2">
              &laquo; Deixe em branco ou com valor "0" para não excluir.
            </div>
          </div>
          <!--<div class="mb-3 row mt-3">
            <div class="col-md-4">
              <div class="form-check form-switch form-switch-md mb-3">
                <input
                  :disabled="lockControlCancelSorting"
                  v-model="controls.keep_pending_latest"
                  class="form-check-input"
                  type="checkbox"
                  id="keep_pending_latest"
                >
                <label class="form-check-label" for="keep_pending_latest">
                  Cancelar pela ordem, pedidos mais antigos
                </label>
              </div>
            </div>
            <div class="col-md-8 pt-1">
              &laquo; Desativar esta opção, faz com que os pedidos mais
              recentes do cliente sejam cancelados durante a checagem
            </div>
          </div>-->
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h4 class="card-title">
                Configuração das Cotas
              </h4>
            </div>
            <div class="col" style="text-align: right;">
              <button
                v-if="isFeatureEnabled('contest-quotas')"
                :disabled="updatingQuotas"
                class="btn btn-outline-success waves-effect waves-light"
                type="button"
                style="width: 15rem;"
                @click="updateQuotas"
              >
                <i
                  class="bx font-size-16 align-middle me-2"
                  :class="updatingQuotas ? 'bx-loader bx-spin' : 'bx-save'"
                ></i>
                {{ updatingQuotas ? 'Atualizando' : 'Atualizar' }} cotas
              </button>
              <upgrade-trigger
                v-else
                class="btn btn-success waves-effect waves-light"
                title="Funcionalidade PREMIUM"
              >
                <template v-slot:icon>
                  <i class="fas fa-star"></i>
                </template>
              </upgrade-trigger>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-3 row mt-3">
            <label for="pending_lifetime" class="col-md-4 col-form-label">
              Quantidade de opções
            </label>
            <div class="col">
              <span class="me-2">
                <input
                  type="radio"
                  class="btn-check"
                  name="options"
                  id="four-items"
                  autocomplete="off"
                  value="4"
                  v-model="optionsQuantity"
                  @click="toggleContestQuotasArraySize(4)"
                >
                <label class="btn btn-primary" for="four-items">4 Itens</label>
              </span>

              <span class="me-2">
                <input
                  type="radio"
                  class="btn-check"
                  name="options"
                  id="six-items"
                  autocomplete="off"
                  value="6"
                  v-model="optionsQuantity"
                  @click="toggleContestQuotasArraySize(6)"
                >
                <label class="btn btn-primary" for="six-items">6 Itens</label>
              </span>
            </div>
            <div class="col">
              <h5>
                Exemplo de como ficará no site
              </h5>
            </div>
          </div>
          <div
            class="row"
          >
            <div
              class="col"
            >
              <div
                class="row"
                v-for="(quota, index) in contestQuotas"
                :key="index"
                :style="`${(index % 2 !== 1) ? 'background: rgba(255, 255, 255, 0.1);' : 'background: rgba(34, 53, 78, 1.0);'} border-radius: 10px;`"
              >
                <div
                  class="mb-3 col-9"
                >
                  <label :for="`value${index}`" class="col-md-4 col-form-label">
                    Valor
                  </label>
                  <div class="col">
                    <input
                      v-model="quota.value"
                      class="form-control text-center"
                      type="number"
                      :id="`value${index}`"
                      min="0"
                    >
                  </div>
                </div>
                <div class="mb-3 col-3 mt-3">
                  <label>
                    Opção em destaque
                  </label>
                  <div class="form-check form-switch form-switch-md mb-3">
                    <input
                      v-model="quota.highlight_option"
                      class="form-check-input"
                      type="checkbox"
                      name="highlight"
                      @click="unmarkOtherCheckboxes(index)"
                      :id="`highlight_option${index}`"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                v-for="(quota, index) in contestQuotas"
                :class="`quota ${(quota.highlight_option ? ' blockcart_packpopular' : '')}`"
                :key="index"
              >
                {{ quota.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlockedNumbersComponent
        :contestId="route.params.id"
      />
    </div>
    <!-- end row -->
  </Layout>
</template>

<style>
  .blockcart_packpopular {
    border: 2px solid rgba(var(--bs-success-rgb)) !important;
  }
  .quota {
    border: 1px white solid;
    text-align: center;
    padding: 20px;
    width: 48%;
    margin: 5px;
    display: inline-block;
  }
</style>

<script>
import Layout from "../../layouts/main"
import { mask } from 'vue-the-mask'
import { computed, onMounted, onBeforeUnmount, reactive, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { finishRaffle, getRaffleNumbersStatus, getRaffle, updateRaffle, removeRaffle, fetchContestQuotas, storeSeveralContestQuotas } from "./api"
import Slider from "@vueform/slider"
import Swal from 'sweetalert2'
import { isFreeAccount } from '@/feature-check'
import { copyContestLink } from './utils'
import BlockedNumbersComponent from '../../../components/raffles/BlockedNumbers.vue'
import { Toasts } from "../../../utils/toast-alerts"

export default {
  name: 'raffleForm',
  components: {
    Layout,
    Slider,
    BlockedNumbersComponent
  },
  directives: {
    mask,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { id } = reactive(route.params)
    let numberWinner = ref(null)
    const selectedMode = ref("hidden")
    const optionsQuantity = ref(0)
    const emptyQuota = ref({
      contest_id: route.params.id,
      value: null,
      highlight_option: false
    })
    const contestQuotas = ref([])
    const formRaffle = ref({
      title: "",
      short_description: "",
      start_date: "",
      price: 0.10,
      quantity: 100,
      max_reserve_days: 1,
      whatsapp_number: "",
      whatsapp_group: "",
      sales: [],
      bank_accounts: [],
      gallery: [],
    })
    const numbersStatus = reactive({
      total: 0,
      free: 0,
      reserved: 0,
      paid: 0,
    })
    const changePercentage = ref({
      percentage_type: 'hidden_percentage',
      custom_percentage: 0,
    })

    const cancelPendingTimeOptions = [
        { text: 'Não cancelar', value: 0},
        { text: '15 minutos', value: 15 },
        { text: '25 minutos', value: 25 },
        { text: '35 minutos', value: 35 },
        { text: '45 minutos', value: 45 },
        { text: '55 minutos', value: 55 },
    ]

    const counters = ref({
      all: {
        color: 'white',
        label: 'Todos os números',
        icon: 'mdi mdi-bullseye-arrow me-3',
        value: 0
      },
      free: {
        color: 'info',
        label: 'Disponíveis',
        icon: 'mdi mdi-bullseye-arrow me-3',
        value: 0
      },
      reserved: {
        color: 'warning',
        label: 'Reservados',
        icon: 'mdi mdi-bullseye-arrow me-3',
        value: 0
      },
      paid: {
        color: 'success',
        label: 'Pagos',
        icon: 'mdi mdi-bullseye-arrow me-3',
        value: 0
      }
    })

    const updatingControls = ref(false)
    const updatingQuotas = ref(false)
    const controls = ref({
      min_order_numbers: 0,
      max_order_numbers: 0,
      max_pending_orders: 0,
      keep_pending_latest: true,
      pending_lifetime: 0,
    })

    const deleteContest = () => {
      Swal.fire({
        titleText: 'Tem certeza que deseja excluir o sorteio?',
        text: 'ATENÇÃO! Esta ação não poderá ser desfeita',
        showCancelButton: true,
        confirmButtonText: "Realmente excluir sorteio",
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#556ee6",
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false
      }).then(({ value }) => {
        if (!value) {
          return
        }
        removeRaffle(id)
          .then(() => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Exclusão programada!",
              text: 'Você ainda verá o sorteio na lista até que a exclusão ocorra.',
              showConfirmButton: true,
              didClose: () => {
                router.push("/raffles-list")
              }
            })
          }).catch((e) => {
            Swal.fire(e?.response?.data?.message)
          })
      })
    }

    const updateControls = () => {
      updatingControls.value = true

      if (controls.value.max_order_numbers > 5000) {
        controls.value.max_order_numbers = 5000
      }
      updateRaffle(formRaffle.value.id, controls.value).then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Controles atualizados com sucesso!",
          showConfirmButton: false,
          timer: 1500
        })
      }).finally(() => {
        updatingControls.value = false
      })
    }

    const updateQuotas = () => {
      updatingQuotas.value = true

      const params = {
        quotas: contestQuotas.value
      }
      storeSeveralContestQuotas(params).then(() => {
        Swal.fire({
          position: "top",
          icon: "success",
          title: "Cotas atualizadas com sucesso!",
          showConfirmButton: false,
          timer: 1500
        })
      }).finally(() => {
        updatingQuotas.value = false
      })
    }

    const finishContest = () => {
      Swal.fire({
        titleText: 'Informe o número vencedor',
        text: 'Encerra o sorteio atual e define o vencedor',
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Finalizar sorteio",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#556ee6",
        cancelButtonText: 'Cancelar',
        preConfirm: number => {
          return new Promise((resolve) => {
            const toInteger = parseInt(number)
            if (isNaN(toInteger)) {
              Swal.fire('Número inválido')
              resolve(false)
            }

            const isValidRange = toInteger >= 0 && toInteger <= (counters.value.all.value - 1)

            if (!isValidRange) {
              Swal.fire('Número inválido')
              resolve(false)
            }

            resolve(number)
          })
        },
        allowOutsideClick: false
      }).then(({ value }) => {
        if (!value) return
        finishRaffle(id, value).then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Sorteio finalizado com sucesso!",
            showConfirmButton: false,
            timer: 1500
          })
          refreshAll()
        }).catch((e) => {
          Swal.fire(e?.response?.data?.message)
        })
      })
    }

    const standbyContest = () => {
      Swal.fire({
        titleText: 'Confirma pausa de vendas?',
        text: 'Pausa as vendas de números do sorteio pelo site',
        showCancelButton: true,
        confirmButtonText: "Pausar vendas",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#556ee6",
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false
      }).then(({ value }) => {
        if (!value) return
        updateRaffle(id, { status: 'STANDBY'}).then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "O sorteio foi colocado em modo de pausa!",
            showConfirmButton: false,
            timer: 1500
          })
          refreshAll()
        }).catch((e) => {
          Swal.fire(e?.response?.data?.message)
        })
      })
    }

    const activeContest = () => {
      Swal.fire({
        titleText: 'Reativar vendas do sorteio?',
        text: 'Ativa as vendas de números do sorteio pelo site',
        showCancelButton: true,
        confirmButtonText: "Reativar vendas",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#556ee6",
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false
      }).then(({ value }) => {
        if (!value) return
        updateRaffle(id, { status: 'ACTIVE'}).then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "O sorteio foi reativado com sucesso!",
            showConfirmButton: false,
            timer: 1500
          })
          refreshAll()
        }).catch((e) => {
          Swal.fire(e?.response?.data?.message)
        })
      })
    }

    const soldOffContest = () => {
      Swal.fire({
        titleText: 'Marcar sorteio como Esgotado?',
        text: 'Marca o sorteio como Esgotado para Vendas.',
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#556ee6",
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false
      }).then(({ value }) => {
        if (!value) return
        updateRaffle(id, { status: 'SOLD_OFF'}).then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "O sorteio foi marcado como esgotado com sucesso!",
            showConfirmButton: false,
            timer: 1500
          })
          refreshAll()
        }).catch((e) => {
          Swal.fire(e?.response?.data?.message)
        })
      })
    }

    const refreshAll = () => {
      getRaffleDetails(id)
      getNumbersStatus(id)
    }

    let refreshId = null

    const prepareContestQuotasArray = () => {
      const params = {
        contest_id: route.params.id
      }
      fetchContestQuotas({ params })
        .then((data) => {
          const quotas = data.map((element) => {
            return {
              contest_id: element.contest_id ?? route.params.id,
              value: element.value,
              highlight_option: (element.highlight_option === 1)
            }
          })

          contestQuotas.value = quotas
          optionsQuantity.value = quotas.length
        })
    }

    onMounted(() => {
      refreshAll()

      prepareContestQuotasArray()

      if (refreshId) {
        clearInterval(refreshId)
      }

      refreshId = setInterval(() => {
        refreshAll()
      }, 15000)
    })

    onBeforeUnmount(() => {
      if (refreshId) {
        clearInterval(refreshId)
      }
    })

    async function getRaffleDetails(idRaffle) {
      try {
        formRaffle.value = await getRaffle(idRaffle)

        if (formRaffle.value.percentage_type == 'custom_percentage') {
          selectedMode.value = 'show_custom'
          changePercentage.value.custom_percentage = formRaffle.value.custom_percentage * 100
        } else if (formRaffle.value.percentage_type == 'actual_percentage') {
          selectedMode.value = 'show_true'
        } else {
          selectedMode.value = 'hidden'
        }

        const {
          min_order_numbers,
          max_order_numbers,
          max_pending_orders,
          keep_pending_latest,
          pending_lifetime,
          website_ranking,
          show_on_home_page,
          slug
        } = formRaffle.value

        controls.value = {
          min_order_numbers,
          max_order_numbers,
          max_pending_orders,
          keep_pending_latest,
          pending_lifetime,
          website_ranking,
          show_on_home_page,
          slug
        }

        const currentPendingOption = cancelPendingTimeOptions.find((o) => {
          return o.value === controls.value.pending_lifetime
        })
        if (!currentPendingOption) {
          for (const option of cancelPendingTimeOptions) {
            if (option.value > controls.value.pending_lifetime) {
              formRaffle.value.pending_lifetime = option.value
              break
            }
          }
        }

      } catch (error) {
        Toasts('error', 'Falha ao carregar sorteio')
      }
    }

    async function getNumbersStatus() {
      try {

        const result = await getRaffleNumbersStatus(id)

        numbersStatus.total = result.total
        numbersStatus.free = result.free
        numbersStatus.reserved = result.reserved
        numbersStatus.paid = result.paid

        counters.value.all.value = result.total
        counters.value.free.value = result.free
        counters.value.reserved.value = result.reserved
        counters.value.paid.value = result.paid

      } catch (error) {
        Toasts('error', 'Falha ao carregar os dados do sorteio')
      }
    }

    async function submitRaffle(){
      try {
        await finishRaffle(id, numberWinner.value)

        numberWinner.value = null
        getRaffleDetails(id)
        getNumbersStatus(id)
        Toasts('success', 'Sorteio finalizado com sucesso')

      }catch (e) {
        Toasts('error', e.response.data.message)
      }
    }

    async function handleChangePercentage() {
      try {
        if (selectedMode.value === "hidden") {
          changePercentage.value.percentage_type = 'hidden_percentage'
        }

        if (selectedMode.value === "show_true") {
          changePercentage.value.percentage_type = 'actual_percentage'
        }

        if (selectedMode.value === "show_custom"){
          changePercentage.value.percentage_type = 'custom_percentage'
        }

        await updateRaffle(id, {
          ...changePercentage.value,
          custom_percentage: changePercentage.value.custom_percentage / 100,
        })

      } catch (error) {
        Toasts('error', 'Falha ao mudar o progresso')
      }
    }

    const addTwoEmptyElements = () => {
      contestQuotas.value.push({ ...emptyQuota.value })
      contestQuotas.value.push({ ...emptyQuota.value })
    }

    const removeTwoElements = () => {
      contestQuotas.value.pop()
      contestQuotas.value.pop()
    }

    const toggleContestQuotasArraySize = (toSize) => {
      const size = contestQuotas.value.length
      if (size === 4 && toSize !== 4) {
        addTwoEmptyElements()
      } else if (size === 6) {
        removeTwoElements()
      }
    }

    const unmarkOtherCheckboxes = (index) => {
      if (contestQuotas.value[index].highlight_option) {
        return
      }
      contestQuotas.value = contestQuotas.value.map((element, id) => {
        if (id !== index) {
          element.highlight_option = false
        }
        return element
      })
    }

    const lockControlCancelSorting = computed(() => {
      const maxPendingUnset = parseInt(controls.value.max_pending_orders) === 0
      const lifetimeUnset = parseInt(controls.value.pending_lifetime) === 0
      return maxPendingUnset || lifetimeUnset
    })

    return {
      formRaffle,
      submitRaffle,
      getRaffleDetails,
      numbersStatus,
      getNumbersStatus,
      numberWinner,
      selectedMode,
      handleChangePercentage,
      changePercentage,
      counters,
      lockControlCancelSorting,
      controls,
      updateControls,
      updatingControls,
      finishContest,
      refreshAll,
      standbyContest,
      activeContest,
      soldOffContest,
      deleteContest,
      optionsQuantity,
      contestQuotas,
      updateQuotas,
      updatingQuotas,
      unmarkOtherCheckboxes,
      toggleContestQuotasArraySize,
      cancelPendingTimeOptions,
      isFreeAccount,
      copyContestLink,
      route
    }
  },
}

</script>
<style>
.center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.card-radio:hover {
  cursor: text;
}
</style>
